<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('creating_fykk_template')"
				        :isFilter="false"/>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('creating_fykk_template')"
				              :isFilter="false"/>
			</template>
			<div class="row align-items-center" v-if="data!=null">
				<div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 ellipsis-1">
					<label class="col-form-label mr-1 p-0">{{ $t('faculty') }}:</label>
					{{ data.faculty_name || "-" }}
				</div>
				<div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 ellipsis-1">
					<label class="col-form-label mr-1 p-0">{{ $t('semester') }}:</label>
					{{ data.academic_year + ' / ' + (data.semester_text || "-") }}
				</div>
				<div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 ellipsis-1">
					<label class="col-form-label mr-1 p-0">{{ $t('date') }}:</label>
					{{ data.date }}
				</div>
				<div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 ellipsis-1">
					<label class="col-form-label mr-1 p-0">{{ $t('subject') }}:</label>
					{{ data.type_text || "-" }}
				</div>
			</div>

			<div class="row mb-5 align-items-center" v-if="data!=null">
				<div class="col-12">
					<div class="row">
						<div class="col-xl-3 col-md-4 col-sm-6 col-xs-12">
							<b-form-group :label="$t('status')">
								<b-form-select
									v-model="form.status">
									<option>{{ $t('select') }}</option>
									<option value="approved">{{ $t('approved') }}</option>
									<option value="declined">{{ $t('declined') }}</option>
								</b-form-select>
							</b-form-group>
						</div>

					</div>
				</div>
                <div class="col-12">
                    <div class="row align-items-center" v-if="students.length>0">
                        <div class="col-12">
                            <table class="table table-striped table-bordered">
                                <tr>
                                    <th class="w-40"></th>
                                    <th>{{ $t('student_number') }}</th>
                                    <th>{{ $t('name') }}</th>
                                    <th>{{ $t('surname') }}</th>
                                    <th>{{ $t('status') }}</th>
                                </tr>
                                <tr v-for="row in students" v-bind:key="row.id">
                                    <td>
                                        <b-form-checkbox v-model="related_ids" :value="row.id"></b-form-checkbox>
                                    </td>
                                    <td>{{ row.student_number }}</td>
                                    <td>{{ row.name }}</td>
                                    <td>{{ row.surname }}</td>
                                    <td>{{ row.status_name }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
				<div class="col-12 mt-3">
					<b-button @click="createDocument"
					          type="button"
					          variant="primary"
					          class="btn-lg">
						{{ $t('create_document') }}
					</b-button>
				</div>
			</div>
		</app-layout>
	</div>
</template>
<script>
	// Template
	import AppLayout from "@/layouts/AppLayout";
	import Header from "@/layouts/AppLayout/Header";
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

	// Services
	import BoardDecisionService from "@/services/BoardDecisionService";
	import LeaveOfAbsenceService from "@/services/LeaveOfAbsenceService";
	import CourseTransferService from "@/services/CourseTransferService";

	// Other
	import qs from "qs";

	export default {
		components: {
			AppLayout,
			Header,
			HeaderMobile
		},
		data() {
			return {
				id: this.$route.params.id,
				form: {},
				data: null,
				students: [],
				related_ids: []
			}
		},
		metaInfo() {
			return {
				title: this.$t("creating_fykk_template")
			}
		},
		created() {
			this.loadData();
		},
		methods: {
			async loadData() {
				if (this.id > 0) {
					await BoardDecisionService.get(this.id)
					                          .then(response => {
						                          this.data = response.data.data;
					                          }).catch(error => {
							if (error.data.message) {
								this.$toast.error(this.$t('api.' + error.data.message));
							}
						});
				}
			},
			getStudents() {
			    if(this.data.type=='course_transfer'){
                    this.getCourseTransfers();
                }
			    else {
			        this.getLeaveAbsences();
                }
			},
            getLeaveAbsences() {
                const config = {
                    params: {
                        filter: {
                            status: this.form.status,
                            academic_year: this.data.academic_year,
                            faculty_code: this.data.faculty_code
                        },
                        limit: 999,
                        sort: 'id'
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                };
                LeaveOfAbsenceService.getAll(config)
                    .then(response => {
                        this.students = response.data.data;
                    }).catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                });
            },
            getCourseTransfers() {
                const config = {
                    params: {
                        filter: {
                            status: this.form.status,
                            academic_year: this.data.academic_year,
                            faculty_code: this.data.faculty_code
                        },
                        limit: 999,
                        sort: 'id'
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                };
                CourseTransferService.getAll(config)
                    .then(response => {
                        this.students = response.data.data;
                    }).catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                });
            },
            async createDocument() {
				if (this.related_ids.length === 0) {
					return;
				}
				let data = {
					status: this.form.status,
					related_ids: this.related_ids
				};
				BoardDecisionService.createDocument(this.data.id, data)
				                    .then(response => {
					                    const blob = new Blob([response.data], {type: response.headers['content-type']})
					                    const data = window.URL.createObjectURL(blob);
					                    var link = document.createElement('a');
					                    link.href = data;
					                    link.download = this.data.decision_number + '-' + this.data.date + '.pdf';
					                    link.click();
					                    setTimeout(function () {
						                    window.URL.revokeObjectURL(data);
					                    }, 100);
				                    })
				                    .catch(e => {
					                    if (e.data.message) {
						                    var decoder = new TextDecoder("utf-8");
						                    var data = JSON.parse(decoder.decode(new Uint8Array(e.data)));
						                    this.$toast.error(this.$t('api.' + data.message));
					                    }
				                    })
			}
		},
		watch: {
			'form.status'() {
				this.getStudents();
			}
		}
	}
</script>
